import {APP_INITIALIZER, Provider} from '@angular/core';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakInitOptions} from 'keycloak-js';
import {environment} from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  const initOptions: KeycloakInitOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
  };
  return async () => {
    keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type === KeycloakEventType.OnTokenExpired) {
          keycloak
            .updateToken()
            .then(res => {
              if (!res) {
                keycloak.login();
              }
            })
            .catch(() => keycloak.login());
        }
      },
    });

    await keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.client,
      },
      loadUserProfileAtStartUp: true,
      initOptions,
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/register'],
    });
  };
}

export function provideKeycloak(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService],
  };
}

export enum ImageFormatEnum {
  ORIGINAL = 'ORIGINAL',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
  R16_9 = 'R16_9',
  R4_3 = 'R4_3',
  PANORAMA = 'PANORAMA',
  PANORAMA_SMALL = 'PANORAMA_SMALL',
  PANORAMA_LARGE = 'PANORAMA_LARGE',
  VIGNETTE = 'VIGNETTE',

  /*
   * LEGACY Format
   * */

  _1 = '_1',
  _2 = '_2',
  _3 = '_3',
  _4 = '_4',
  _5 = '_5',
  _6 = '_6',
  _7 = '_7',
  _8 = '_8',
  _9 = '_9',
  _10 = '_10',
  _11 = '_11',
  _12 = '_12',
  _13 = '_13',
}

export interface ImageFormatInfo {
  format: ImageFormatEnum;
  label?: string;
  dimensions: string[];
  visible: boolean;
  ratio?: number;
}

export const ImageFormatData: Record<ImageFormatEnum, ImageFormatInfo> = {
  [ImageFormatEnum.ORIGINAL]: {
    format: ImageFormatEnum.ORIGINAL,
    label: $localize`Original`,
    dimensions: [],
    visible: true,
  },
  [ImageFormatEnum.PORTRAIT]: {
    format: ImageFormatEnum.PORTRAIT,
    label: $localize`Portrait`,
    dimensions: ['300x400'],
    visible: true,
    ratio: 0.75,
  },
  [ImageFormatEnum.SQUARE]: {
    format: ImageFormatEnum.SQUARE,
    label: $localize`Square`,
    dimensions: ['500x500', '250x250'],
    visible: true,
    ratio: 1,
  },
  [ImageFormatEnum.R16_9]: {
    format: ImageFormatEnum.R16_9,
    label: $localize`16/9`,
    dimensions: ['800x450', '600x338', '400x225', '300x170', '200x112'],
    visible: true,
    ratio: 16 / 9,
  },
  [ImageFormatEnum.R4_3]: {
    format: ImageFormatEnum.R4_3,
    label: $localize`4/3`,
    dimensions: ['400x300', '300x225'],
    visible: true,
    ratio: 4 / 3,
  },
  [ImageFormatEnum.PANORAMA]: {
    format: ImageFormatEnum.PANORAMA,
    label: $localize`Panorama`,
    dimensions: ['1920x512', '1200x320', '400x106'],
    visible: true,
    ratio: 3.75,
  },
  [ImageFormatEnum.PANORAMA_SMALL]: {
    format: ImageFormatEnum.PANORAMA_SMALL,
    label: $localize`Small Panorama`,
    dimensions: ['1200x436', '800x290', '400x145'],
    visible: true,
    ratio: 2.75,
  },
  [ImageFormatEnum.PANORAMA_LARGE]: {
    format: ImageFormatEnum.PANORAMA_LARGE,
    label: $localize`Large Panorama`,
    dimensions: ['1920x425'],
    visible: true,
    ratio: 4.5,
  },
  [ImageFormatEnum.VIGNETTE]: {
    format: ImageFormatEnum.VIGNETTE,
    label: $localize`Vignette`,
    dimensions: [],
    visible: false,
  },
  [ImageFormatEnum._1]: {format: ImageFormatEnum._1, dimensions: [], visible: false},
  [ImageFormatEnum._2]: {format: ImageFormatEnum._2, dimensions: ['750x425'], visible: false},
  [ImageFormatEnum._3]: {format: ImageFormatEnum._3, dimensions: ['350x198'], visible: false},
  [ImageFormatEnum._4]: {format: ImageFormatEnum._4, dimensions: ['1270x290'], visible: false},
  [ImageFormatEnum._5]: {format: ImageFormatEnum._5, dimensions: ['850x565'], visible: false},
  [ImageFormatEnum._6]: {format: ImageFormatEnum._6, dimensions: ['450x300'], visible: false},
  [ImageFormatEnum._7]: {format: ImageFormatEnum._7, dimensions: ['240x360'], visible: false},
  [ImageFormatEnum._8]: {format: ImageFormatEnum._8, dimensions: ['700x500'], visible: false},
  [ImageFormatEnum._9]: {format: ImageFormatEnum._9, dimensions: ['350x250'], visible: false},
  [ImageFormatEnum._10]: {format: ImageFormatEnum._10, dimensions: ['500x500'], visible: false},
  [ImageFormatEnum._11]: {format: ImageFormatEnum._11, dimensions: ['250x250'], visible: false},
  [ImageFormatEnum._12]: {format: ImageFormatEnum._12, dimensions: ['1000x605'], visible: false},
  [ImageFormatEnum._13]: {format: ImageFormatEnum._13, dimensions: ['1950x650'], visible: false},
};

export const ALL_VISIBLE_FORMATS = Object.entries(ImageFormatData)
  .filter(([_, v]) => v.visible)
  .map(([_, v]) => v.format);

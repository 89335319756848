import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {errorInterceptor} from '@interceptors/error.interceptor';
import {provideKeycloak} from '@providers/keycloak';
import {provideSentry} from '@providers/sentry';
import {KeycloakAngularModule} from 'keycloak-angular';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';

import {routes} from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([errorInterceptor])),
    ConfirmationService,
    MessageService,
    DialogService,
    importProvidersFrom(KeycloakAngularModule),
    provideKeycloak(),
    provideSentry(),
  ],
};

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RegisterRequest} from '@domain/auth/register-request';
import {UserDto} from '@domain/auth/user.dto';
import {KeycloakService} from 'keycloak-angular';
import {catchError, first, Observable, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CurrentUser} from './current-user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly currentUser = inject(CurrentUser);
  private readonly keycloak = inject(KeycloakService);
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  get isLogged() {
    return this.keycloak.isLoggedIn();
  }

  login() {
    return this.keycloak.login();
  }

  logout() {
    this.currentUser.set(null);

    return this.keycloak.logout();
  }

  register(request: RegisterRequest): Observable<UserDto> {
    return this.http.post<UserDto>(environment.api.bff + '/users', request).pipe(first());
  }

  me(): Observable<UserDto> {
    return this.http.get<UserDto>(environment.api.bff + '/users/me').pipe(
      first(),
      tap(user => this.currentUser.set(user)),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403) {
          this.router.navigate(['denied']);
        }

        throw err;
      })
    );
  }
}

import {Injectable, signal} from '@angular/core';
import {Params} from '@angular/router';
import {ALL_VISIBLE_FORMATS, ImageFormatEnum} from '@domain/media/image-format.enum';
import {DOCUMENT_MEDIA_TYPES, IMAGE_MEDIA_TYPES, MediaTypeEnum, VIDEO_MEDIA_TYPES} from '@domain/media/media-type.enum';

@Injectable({
  providedIn: 'root',
})
export class IframeStore {
  iframe = signal<boolean>(false);

  mediaType = signal<'IMAGE' | 'VIDEO' | 'PDF'>('IMAGE');
  searchedFormat = signal<ImageFormatEnum>(undefined);
  searchedDimensions = signal<string>(undefined);

  legacyConvert(params: Params) {
    if (!this.iframe()) {
      return {};
    }

    const mediaType = params['media_type'];
    const format = params['format'];

    const {availableFormats, dimensions} = this.legacyConvertFormat(format);

    return {
      types: this.legacyConvertMediaType(mediaType),
      availableFormats,
      dimensions,
    } as {types: MediaTypeEnum[]; availableFormats: ImageFormatEnum[]; dimensions: string[]};
  }

  private legacyConvertMediaType(mediaType: string): MediaTypeEnum[] {
    let mediaTypes: MediaTypeEnum[] = [];

    if (!mediaType) {
      return mediaTypes;
    }

    switch (mediaType) {
      case '2':
        this.mediaType.set('VIDEO');
        mediaTypes = VIDEO_MEDIA_TYPES;
        break;
      case '3':
        this.mediaType.set('PDF');
        mediaTypes = DOCUMENT_MEDIA_TYPES;
        break;
      default:
        this.mediaType.set('IMAGE');
        mediaTypes = IMAGE_MEDIA_TYPES;
    }

    return mediaTypes;
  }

  private legacyConvertFormat(format: string): {availableFormats: ImageFormatEnum[]; dimensions: string[]} {
    let availableFormats: ImageFormatEnum[] = [];
    let dimensions: string[] = [];

    if (!format) {
      return {availableFormats, dimensions};
    }

    switch (format) {
      case '1':
        availableFormats = [ImageFormatEnum.ORIGINAL];
        this.searchedFormat.set(ImageFormatEnum.ORIGINAL);
        break;
      case '2':
      case '8':
        availableFormats = [ImageFormatEnum.R16_9];
        dimensions = ['800x450'];
        this.searchedFormat.set(ImageFormatEnum.R16_9);
        this.searchedDimensions.set('800x450');
        break;
      case '3':
      case '5':
      case '6':
        availableFormats = [ImageFormatEnum.R16_9];
        dimensions = ['400x225'];
        this.searchedFormat.set(ImageFormatEnum.R16_9);
        this.searchedDimensions.set('400x225');
        break;
      case '7':
        availableFormats = [ImageFormatEnum.PORTRAIT];
        dimensions = ['300x400'];
        this.searchedFormat.set(ImageFormatEnum.PORTRAIT);
        this.searchedDimensions.set('300x400');
        break;
      case '9':
        availableFormats = [ImageFormatEnum.R4_3];
        dimensions = ['400x300'];
        this.searchedFormat.set(ImageFormatEnum.R4_3);
        this.searchedDimensions.set('400x300');
        break;
      case '10':
        availableFormats = [ImageFormatEnum.SQUARE];
        dimensions = ['500x500'];
        this.searchedFormat.set(ImageFormatEnum.SQUARE);
        this.searchedDimensions.set('500x500');
        break;
      case '11':
        availableFormats = [ImageFormatEnum.SQUARE];
        dimensions = ['250x250'];
        this.searchedFormat.set(ImageFormatEnum.SQUARE);
        this.searchedDimensions.set('250x250');
        break;
      case '12':
      case '13':
        availableFormats = [ImageFormatEnum.PANORAMA];
        dimensions = ['1920x512'];
        this.searchedFormat.set(ImageFormatEnum.PANORAMA);
        this.searchedDimensions.set('1920x512');
        break;
      default:
        availableFormats = ALL_VISIBLE_FORMATS;
    }

    return {availableFormats, dimensions};
  }
}

/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import {AppComponent} from '@app/app.component';
import {appConfig} from '@app/app.config';
import {environment} from './environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  enabled: environment.production,
  environment: environment.environment,
  release: `${environment.sentry.packageName}@${environment.sentry.packageVersion}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\/ api/, 'localhost'],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

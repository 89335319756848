export enum MediaTypeEnum {
  PHOTO = 'PHOTO',
  DOCUMENT = 'DOCUMENT',
  BOOKLET = 'BOOKLET',
  MAP = 'MAP',
  PLAN = 'PLAN',
  VIDEO = 'VIDEO',
  LOGO = 'LOGO',
  PICTO = 'PICTO',
}

interface MediaTypeInfo {
  label: string;
  picto: string;
}

export const MediaTypeData: Record<MediaTypeEnum, MediaTypeInfo> = {
  [MediaTypeEnum.PHOTO]: {label: $localize`Images`, picto: 'ce-multibloc'},
  [MediaTypeEnum.DOCUMENT]: {label: $localize`Documents`, picto: 'ce-document'},
  [MediaTypeEnum.BOOKLET]: {label: $localize`Booklets`, picto: 'ce-flyer'},
  [MediaTypeEnum.MAP]: {label: $localize`Maps`, picto: 'ce-plan'},
  [MediaTypeEnum.PLAN]: {label: $localize`Plan`, picto: 'ce-plan'},
  [MediaTypeEnum.VIDEO]: {label: $localize`Videos`, picto: 'ce-video-1'},
  [MediaTypeEnum.LOGO]: {label: $localize`Logos`, picto: 'ce-logo'},
  [MediaTypeEnum.PICTO]: {label: $localize`Pictos`, picto: 'ce-icon'},
};

export const IMAGE_MEDIA_TYPES: MediaTypeEnum[] = [
  MediaTypeEnum.PHOTO,
  MediaTypeEnum.LOGO,
  MediaTypeEnum.PICTO,
  MediaTypeEnum.MAP,
  MediaTypeEnum.PLAN,
];
export const UNFRAMABLE_MEDIA_TYPES: MediaTypeEnum[] = [
  MediaTypeEnum.LOGO,
  MediaTypeEnum.PICTO,
  MediaTypeEnum.MAP,
  MediaTypeEnum.PLAN,
];
export const DOCUMENT_MEDIA_TYPES: MediaTypeEnum[] = [MediaTypeEnum.DOCUMENT, MediaTypeEnum.BOOKLET];
export const LOCALIZED_MEDIA_TYPES: MediaTypeEnum[] = [...DOCUMENT_MEDIA_TYPES, MediaTypeEnum.MAP, MediaTypeEnum.PLAN];
export const VIDEO_MEDIA_TYPES: MediaTypeEnum[] = [MediaTypeEnum.VIDEO];

export const IMAGE_DOCUMENT_MEDIA_TYPES = [...IMAGE_MEDIA_TYPES, ...DOCUMENT_MEDIA_TYPES];

import packageInfo from 'package.json';

export const environment = {
  production: true,
  environment: 'PROD',
  sentry: {
    dsn: 'https://663af1436797d81312af61a3300f349a@o4507061083963392.ingest.de.sentry.io/4507645319250000',
    packageName: packageInfo.name,
    packageVersion: packageInfo.version,
  },
  explorer: {
    targetOrigin: 'https://croisitek-prod.dpk-croisieurope-cl02.agoracalyce.net',
  },
  api: {
    media: 'https://images-prod.dpk-croisieurope-cl02.agoracalyce.net',
    bff: '/api',
  },
  keycloak: {
    url: 'https://auth.croisieurope.com',
    realm: 'croisi',
    client: 'croisi-medialib-web',
  },
};

import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {ApiException} from '@domain/exceptions/api-exception';
import {CommonMessageLocalized} from '@i18n/common-message-localized';
import {ErrorMessageLocalized} from '@i18n/error-message-localized';
import {MessageService} from 'primeng/api';
import {catchError, throwError} from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);

  return next(req).pipe(
    catchError(err => {
      const exception = Object.assign(new ApiException(), err.error);

      messageService.add({
        severity: 'error',
        summary: CommonMessageLocalized.ERROR,
        detail: ErrorMessageLocalized[exception.error],
      });

      return throwError(() => err);
    })
  );
};

import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {MedialibRole} from '@directives/security/role.types';
import {CommonMessageLocalized} from '@i18n/common-message-localized';
import {ErrorMessageLocalized} from '@i18n/error-message-localized';
import {AuthService} from '@services/auth.service';
import {CurrentUser} from '@services/current-user';
import {MessageService} from 'primeng/api';
import {firstValueFrom} from 'rxjs';

export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const currentUser = inject(CurrentUser);
  const messageService = inject(MessageService);

  if (!authService.isLogged) {
    await authService.login();
  }

  if (currentUser.isAnonymous) {
    await firstValueFrom(authService.me());
  }

  const authorizedRoles: MedialibRole[] = route.data['roles'] ?? [];

  if (currentUser.hasOrRole(authorizedRoles)) {
    return true;
  }

  messageService.add({
    severity: 'error',
    summary: CommonMessageLocalized.ERROR,
    detail: ErrorMessageLocalized.ACCESS_DENIED,
  });

  return router.navigate(['']);
};

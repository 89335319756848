import {CommonModule} from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {HasOrRoleDirective} from '@directives/security/has-or-role.directive';
import {HasRoleDirective} from '@directives/security/has-role.directive';
import {IframeStore} from '@stores/iframe.store';
import {PrimeNGConfig} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';

@Component({
  selector: 'croisi-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ConfirmDialogModule, ToastModule, HasRoleDirective, HasOrRoleDirective],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly primengConfig = inject(PrimeNGConfig);
  private readonly iframeStore = inject(IframeStore);

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.iframeStore.iframe.set(window.self !== window.top);
  }
}

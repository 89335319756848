export class CommonMessageLocalized {
  static readonly OK = $localize`Ok`;
  static readonly YES = $localize`Yes`;
  static readonly NO = $localize`No`;
  static readonly EMPTY = $localize`Empty`;
  static readonly UPDATE_CONFIRM_HEADER = $localize`Update confirmation`;
  static readonly DELETE_CONFIRM_HEADER = $localize`Delete Confirmation`;
  static readonly DELETE_CONFIRM = $localize`Are you sure you want to delete this element?`;
  static readonly CREATE = $localize`Create`;
  static readonly CREATED = $localize`Created`;
  static readonly ADD = $localize`Add`;
  static readonly SAVE = $localize`Save`;
  static readonly SAVED = $localize`Saved`;
  static readonly DELETE = $localize`Delete`;
  static readonly DELETED = $localize`Deleted`;
  static readonly ERROR = $localize`Error`;
  static readonly SUCCESS = $localize`Success`;
  static readonly UPDATE = $localize`Update`;
  static readonly PUBLISH = $localize`Publish`;
  static readonly PUBLISHED = $localize`Published`;
  static readonly PREVIEW = $localize`Preview`;
  static readonly EDIT = $localize`Edit`;
  static readonly CANCEL = $localize`Cancel`;
}

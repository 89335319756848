import {Routes} from '@angular/router';
import {MedialibRole} from '@directives/security/role.types';
import {authGuard} from '@guards/auth.guard';
import {iframeGuard} from '@guards/iframe.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./home/home.component').then(mod => mod.HomeComponent),
    canActivate: [authGuard, iframeGuard],
  },
  {
    path: 'search',
    loadComponent: () => import('./search/search.component').then(mod => mod.SearchComponent),
    canActivate: [authGuard, iframeGuard],
  },
  {
    path: 'medias/:id',
    loadComponent: () => import('./media/media.component').then(mod => mod.MediaComponent),
    canActivate: [authGuard, iframeGuard],
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin.component').then(mod => mod.AdminComponent),
    canActivate: [authGuard, iframeGuard],
    data: {
      roles: ['ADMIN'] as MedialibRole[],
    },
  },
  {
    path: 'register',
    loadComponent: () => import('./register/register.component').then(mod => mod.RegisterComponent),
    canActivate: [iframeGuard],
  },
  {
    path: 'denied',
    loadComponent: () => import('./denied/denied.component').then(mod => mod.DeniedComponent),
    canActivate: [],
  },
];

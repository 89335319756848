import {APP_INITIALIZER, ErrorHandler, Provider} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular';

export function provideSentry(): Provider[] {
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

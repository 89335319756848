import {ArrayUtil, ObjectUtil, StringUtil} from '@030_croisieurope/croisi-front-library';
import {computed, Injectable, signal} from '@angular/core';
import {MedialibRole, REALM_ROLES} from '@directives/security/role.types';
import {UserDto} from '@domain/auth/user.dto';

@Injectable({
  providedIn: 'root',
})
export class CurrentUser {
  private readonly _currentUser = signal<UserDto>(null);
  private readonly _isAdmin = computed(() => {
    const user = this.user;

    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    if (ArrayUtil.isEmpty(user.roles)) {
      return false;
    }

    return user.roles.findIndex(r => r.name === 'GLOBAL_ADMIN') !== -1;
  });

  get isAnonymous() {
    return ObjectUtil.isNullOrUndefined(this._currentUser());
  }

  get isAdmin() {
    return this._isAdmin();
  }

  get user() {
    return this._currentUser();
  }

  get id() {
    return this._currentUser()?.id;
  }

  get email() {
    return this._currentUser()?.email;
  }

  get roles() {
    return this._currentUser()?.roles ?? [];
  }

  set(user: UserDto) {
    this._currentUser.set(user);
  }

  hasRole(roleNames: MedialibRole[]): boolean {
    const user = this.user;

    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    const realmRoles = roleNames.map(r => REALM_ROLES[r]);
    const roleNamesCleaned = realmRoles.map(r => r.trim()).filter(StringUtil.isNotBlank);

    if (ArrayUtil.isEmpty(roleNamesCleaned) || this.isAdmin) {
      return true;
    }

    const userRoleName = user.roles.map(r => r.name);

    return roleNamesCleaned.every(roleName => userRoleName.includes(roleName));
  }

  hasOrRole(roleNames: MedialibRole[]): boolean {
    const user = this.user;

    if (ObjectUtil.isNullOrUndefined(user)) {
      return false;
    }

    const realmRoles = roleNames.map(r => REALM_ROLES[r]);
    const roleNamesCleaned = realmRoles.map(r => r.trim()).filter(StringUtil.isNotBlank);

    if (ArrayUtil.isEmpty(roleNamesCleaned) || this.isAdmin) {
      return true;
    }

    const userRoleName = user.roles.map(r => r.name);

    return roleNamesCleaned.some(roleName => userRoleName.includes(roleName));
  }
}
